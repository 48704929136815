import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as T } from 'react-intl';

import { Cover, FAQs, GridResults, Image, Layout, Link, SEO } from 'components';
import { routes } from 'utils/routes';
import reviewSelfies from 'utils/config/reviewSelfies';
import { pricingENFaqsData, pricingUKFaqsData } from 'utils/config/faqs';

import content from './pricing.yml';

const PricingPage = ({ pageContext: { locale }, location }) => (
  <Layout locale={locale} className='pricing-wrapper'>
    <SEO
      title={content.title}
      description={content.description}
      lang={locale}
      keywords={content.keywords}
      location={location}
      image={content.socialImage}
    />

    <Link to={routes.apart.path} {...routes.apart} className='cta-sidetab hide-mobile'>What sets us apart?</Link>

    <Cover
      title='Smile More.<br/>Pay Less.'
      image={{ url: 'pages/pricing_cover.jpg', alt: 'Smile more. Pay less' }}
      button={{ label: 'Get started', ...routes.getStarted }}
      overlap
      trustpilot
      className='cover-pricing'
    />

    <div className='section-info-pricing fvw mb-50'>
      <div className='content-wrapper'>
        <p><b>Don’t pay a penny before finding out if you’re suitable first</b></p>
        <div className='info-pricing'>
          <Image url='icons/icon_commitment.svg' alt='Paper with results' />
          <p className='small ml-10'>See your results before commiting to the full treatment for only <T id="pricing.start">{txt => txt}</T></p>
        </div>
        <div className='info-pricing'>
          <Image url='icons/icon_schedule.svg' alt='Schedule payments' />
          <p className='small ml-10'>3 easy payment options with no credit checks,from <T id="pricing.month">{txt => txt}</T> / month</p>
        </div>
      </div>
    </div>

    <div className='section-pricing'>
      <div className='section-media'>
        <Image url='pages/pricing_smilebox.png' alt='Smilebox' />
      </div>
      <div className='section-text'>
        <h3>Step One</h3>
        <h1 className='mt-10 mb-10'>SmileBox<sup>&trade;</sup></h1>
        <p>[Home impression kit + personalised plan]</p>
        <div className='mt-20'>
          <T id="pricing.smilebox">
            {txt => <p className='price' dangerouslySetInnerHTML={{ __html: txt }} />}
          </T>
        </div>
        {locale === 'uk' &&
          <Fragment>
            <h4 className='small mt-20 mb-10'>Don’t mind traveling?</h4>
            <p>Get your teeth professionally 3D scanned in central London.<br/>Same price - 15 min appointment - hassle free.<br/>This option can be booked via the app as an alternative to the impression kit.</p>
          </Fragment>
        }
      </div>
    </div>

    <div className='section-pricing-icons'>
      <p className='mb-20'><b>This covers:</b></p>
      <div className='flex'>
        <div className='icon-wrapper'>
          <Image url='icons/icon_postage.svg' alt='Decorative icon' />
          <p>Postage to<br/>and from you</p>
        </div>
        <div className='icon-wrapper'>
          <Image url='icons/icon_mould.svg' alt='Decorative icon' />
          <p>All impression<br/>needed</p>
        </div>
      </div>
    </div>

    <hr className='fvw' />

    <div className='section-pricing'>
      <div className='section-media'>
        <Image url='pages/pricing_straightbox.png' alt='Straightbox' />
      </div>
      <div className='section-text'>
        <h3>Step Two</h3>
        <h1 className='mt-10 mb-10'>StraightBox<sup>&trade;</sup></h1>
        <p>[Custom aligners + Dentist monitoring]</p>
        <div className='mt-20'>
          <T id="pricing.straightbox">{txt => <p className='price'>{txt}</p>}</T>
        </div>
        <p className='mb-20'>Pay in one go</p>
        <hr/>
        <h4 className='mb-10'>3 installments</h4>
        <p>Upfront : <T id="pricing.installments.upfront">{txt => txt}</T> — Installments : <T id="pricing.installments.value">{txt => txt}</T> — 2 and 4 months later</p>
        <hr/>
        <h4 className='mb-10'>Monthly</h4>
        <p>Upfront : <T id="pricing.monthly.upfront">{txt => txt}</T> — Monthly : <T id="pricing.monthly.month">{txt => txt}</T>/month — for 8 months</p>
        <hr/>
        {locale === 'uk' &&
          <Fragment>
            <h4 className='mb-10'>Finance</h4>
            <p>From <T id="pricing.finance.month">{txt => txt}</T>/month — for 36 months</p>
            <hr/>
          </Fragment>
        }
      </div>
    </div>

    <div className='section-pricing-icons'>
      <p className='mb-20'><b>This covers:</b></p>
      <div className='flex'>
        <div className='icon-wrapper'>
          <Image url='icons/icon_aligners.svg' alt='Decorative icon' />
          <p>All aligners<br/>top & bottom</p>
        </div>
        <div className='icon-wrapper'>
          <Image url='icons/icon_tracking_progress.svg' alt='Decorative icon' />
          <p>Monthly progress<br/>tracking</p>
        </div>
        <div className='icon-wrapper'>
          <Image url='icons/icon_retainers.svg' alt='Decorative icon' />
          <p>Free set<br/>of retainers</p>
        </div>
      </div>
    </div>

    <hr className='fvw' />

    <h2 className='t-center mb-50'>Smile Upgrades</h2>

    <div className='section-pricing-columns fvw'>
      <div className='column'>
        <div className='section-media'>
          <Image url='pages/pricing_smileupgrade.jpg' alt='Whitening upgrade' />
        </div>
        <div className='section-text t-center'>
          <Image url='icons/icon_whitening.svg' alt='Decorative icon' />
          <h2 className='mt-20 mb-20'>Whitening upgrade</h2>
          <p className='price'><T id="pricing.whitening">{txt => txt}</T></p>
          <p>World’s most efficient and long lasting dentist perscribed teeth whitening product</p>
          <Link to={routes.teethWhitening.path} {...routes.teethWhitening} className='button outline mt-20'>Learn more</Link>
        </div>
      </div>
      <div className='column reverse'>
        <div className='section-media'>
          <Image url='pages/pricing_maintainerplan.jpg' alt='Maintainer plan' />
        </div>
        <div className='section-text t-center'>
          <Image url='icons/icon_maintainer.svg' alt='Decorative icon' />
          <h2 className='mt-20 mb-20'>Maintainer plan</h2>
          <p className='price'><T id="pricing.maintainer">{txt => txt}</T>/year</p>
          <p>Receive new retainers every 6 months and extend your guarantee against broken/lost retainers + tooth movement</p>
          <Link to={routes.maintainerPlan.path} {...routes.maintainerPlan} className='button outline mt-20'>Learn more</Link>
        </div>
      </div>
    </div>

    <hr className='fvw' />

    <FAQs title='Pricing FAQs' faqsData={locale === 'uk' ? pricingUKFaqsData : pricingENFaqsData} className='mb-50 mt-90' />

    <h3 className='section-title mb-50 mt-90'>#MySmileDelivered reviews</h3>

    <GridResults link={{ label: 'View more results' }} pictures={reviewSelfies} trustpilot slide hasAction className='mb-50' />
  </Layout>
);

PricingPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
};

export default PricingPage;
